import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import style from './TextPlay.module.scss';

let doAnimateIn;
let doAnimateOut;

export const animateTextIn = () => {
  if (doAnimateIn) {
    doAnimateIn();
  }
};

export const animateTextOut = () => {
  if (doAnimateOut) {
    doAnimateOut();
  }
};

export const TextPlay = () => {
  const navigate = useNavigate();
  const content = useSelector((models) => models.appModel.content.pages.play);

  const [isVisible, setIsVisible] = useState(false);

  const { gameId } = useParams();

  doAnimateIn = () => {
    setIsVisible(true);
  };

  doAnimateOut = () => {
    setIsVisible(false);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={style.wrap}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ duration: 0.5 }}
          onAnimationComplete={(variant) => {
            if (variant === 'hidden') {
              // after animating out, navigate to the next page
              navigate('/' + gameId + '/result');
            }
          }}
        >
          <div className={style.title}>
            <h1>{content.title}</h1>
          </div>
          <div className={style.body}>
            <p>{content.body}</p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TextPlay;
