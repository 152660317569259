import { useSelector } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import clsx from 'clsx';

import style from './AgeScreener.module.scss';

export const AgeScreener = ({ formCallback }) => {
  const content = useSelector((models) => models.appModel.content.pages.age_screener);

  const minAgeYears = new Date().getFullYear() - content.min_age;
  const minAgeMonth = new Date().getMonth() + 1;
  const minAgeDays = new Date().getDate();

  const initialValue = {
    year: '',
    month: '',
    day: '',
  };

  const validationSchema = yup.object({
    year: yup
      .number()
      .integer()
      .required(content.validation_year)
      .lessThan(minAgeYears + 1, content.validation),
    month: yup.number().when('year', {
      is: (value) => parseInt(value) === minAgeYears,
      then: yup
        .number()
        .integer()
        .required(content.validation_month)
        .lessThan(minAgeMonth + 1, content.validation),
    }),
    day: yup.number().when('year', {
      is: (value) => parseInt(value) === minAgeYears,
      then: yup.number().when('month', {
        is: (value) => parseInt(value) === minAgeMonth,
        then: yup
          .number()
          .integer()
          .required(content.validation_day)
          .lessThan(minAgeDays + 1, content.validation),
      }),
    }),
  });

  // const onSubmit = (values, { setFieldError, setSubmitting }) => {
  const onSubmit = () => {
    if (formCallback) {
      formCallback(true);
    }
  };

  const onUnderage = () => {
    if (formCallback) {
      formCallback(false);
    }
  };

  const renderYears = () => {
    let html = [];
    for (let i = 2022; i > 1900; i--) {
      html.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return html;
  };

  const renderMonths = () => {
    let html = [];
    for (let i = 1; i <= 12; i++) {
      html.push(
        <option key={i} value={i}>
          {i < 10 ? '0' + i : i}
        </option>
      );
    }
    return html;
  };

  const renderDays = () => {
    let html = [];
    for (let i = 1; i <= 31; i++) {
      html.push(
        <option key={i} value={i}>
          {i < 10 ? '0' + i : i}
        </option>
      );
    }
    return html;
  };

  return (
    <Formik initialValues={initialValue} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ dirty, isSubmitting, isValid, errors, touched, values }) => {
        const isYearBoundary = values.year && parseInt(values.year) === minAgeYears;
        const isMonthBoundary = isYearBoundary && values.month && parseInt(values.month) === minAgeMonth;
        return (
          <>
            <div className={style.title}>
              <h1>{content.title}</h1>
            </div>
            <div className={style.body}>
              <p>{content.body}</p>
            </div>
            <Form className={style.form}>
              {content.show_dob_form && (
                <>
                  <div className={clsx(style.fields, { [style.multiple]: isYearBoundary })}>
                    <Field as="select" name="year">
                      <option disabled value="">
                        YYYY
                      </option>
                      {renderYears()}
                    </Field>

                    {isYearBoundary && (
                      <>
                        <Field as="select" name="month">
                          <option disabled value="">
                            MM
                          </option>
                          {renderMonths()}
                        </Field>
                      </>
                    )}
                  </div>

                  {isMonthBoundary && (
                    <div className={clsx(style.fields, { [style.multiple]: isYearBoundary })}>
                      <Field as="select" name="day">
                        <option disabled value="">
                          DD
                        </option>
                        {renderDays()}
                      </Field>
                    </div>
                  )}

                  <div className={style.error}>
                    <ErrorMessage name="year" />
                    <ErrorMessage name="month" />
                    <ErrorMessage name="day" />
                  </div>

                  <button
                    type="submit"
                    className={style.button}
                    // disabled={!isValid || isSubmitting}
                  >
                    {content.button_over_age}
                  </button>
                </>
              )}

              {!content.show_dob_form && (
                <button type="button" className={style.button} onClick={onSubmit}>
                  {content.button_over_age}
                </button>
              )}

              <button type="button" className={clsx(style.button, style.outline)} onClick={onUnderage}>
                {content.button_under_age}
              </button>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default AgeScreener;
