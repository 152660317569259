import { useSelector } from 'react-redux';

import style from './Brand.module.scss';

export const Brand = () => {
  const content = useSelector((models) => models.appModel.content.pages.brand);

  return (
    <div className={style.wrap}>
      {content.titleTop && <div className={style.title}>{content.titleTop}</div>}
      <div className={style.prefix}>{content.prefix}</div>
      {content.title && <div className={style.title}>{content.title}</div>}
      {content.image && <img src={content.image} alt="brand_image" />}
    </div>
  );
};

export default Brand;
